import React from "react";
import icon1 from "../assets/images/Drafting Compass.png";
import image1 from "../assets/images/graphic-images.png";
import icon2 from "../assets/images/Guarantee.png";
import image2 from "../assets/images/high-quality.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import useDocumentTitle from "../helper/useDocumentTitle";

function Page2() {
  useDocumentTitle("GROV | Graphic design");
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col gap-20 pt-9 sm:pt-14 px-3 sm:px-0">
        <Card
          title="Graphic design"
          icon={icon1}
          src={image1}
          p1="In designing your company's graphic identity, we don't just follow aesthetics. Our goal is to establish a deep connection that goes beyond mere visual impressions. Together with you, we strive to build a strong bridge that merges our creative ideas with your mission, creating a unique and exceptional graphic identity.
          Our approach focuses on creating minimalist yet highly functional graphic solutions. We reduce unnecessary elements while increasing the value of every visual aspect we create. Our commitment to regular communication with weekly updates and frequent meetings allows us to receive your feedback in real-time and continuously adapt our work to align with your desires and needs."
          p2="Our creativity is your strength - together, we craft visual stories that reflect and strengthen your brand."
        />
        <Card
          title="High quality"
          icon={icon2}
          src={image2}
          p1="Our commitment to quality is the cornerstone of every project. Each final product we design is the result of meticulous research, thoughtful design, and careful execution. We adhere to the highest industry standards and conduct thorough quality reviews to ensure that every graphic element or designed detail perfectly meets your requirements and expectations."
          p2="Our goal is not only to create beauty but also functionality. Every product we deliver is the result of careful planning and execution to ensure that your company's graphic identity not only stands out but also effectively communicates with the target audience. Our product quality is not just a promise but a central part of our creative process, aimed at ensuring excellence in every aspect of your company's graphic identity."
        />
      </div>
    </>
  );
}

export default Page2;
