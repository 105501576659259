import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import MainLayout from "./layouts/MainLayout";
import Page1 from "./screens/Page1";
import Page2 from "./screens/Page2";
import Page3 from "./screens/Page3";
import Page4 from "./screens/Page4";
import Page5 from "./screens/Page5";
import Page6 from "./screens/Page6";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Grov Agency",
              "url": "https://grovagency.com",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://grovagency.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Grov Agency",
              "url": "https://grovagency.com",
              "logo": "src\assets\images\square-logo.png",
              "sameAs": [
                "https://www.facebook.com/grovagency",
                "https://www.twitter.com/grovagency",
                "https://www.linkedin.com/company/grovagency"
              ]
            }
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Prijava",
              "url": "https://grovagency.com/login",
              "description": "Uživaj v vseh prednostih, ki ti jih prinaša Grov Agency račun."
            }
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Registracija",
              "url": "https://grovagency.com/register",
              "description": "Uživaj v vseh prednostih, ki ti jih prinaša Grov Agency račun. Prijava ..."
            }
          `}
        </script>
      </Helmet>
      <ScrollToTop />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route index path="/home" element={<Home />} />
          <Route index path="/spletni-razvoj" element={<Page1 />} />
          <Route index path="/graficna-podoba" element={<Page2 />} />
          <Route index path="/oglasevanje" element={<Page3 />} />
          <Route index path="/o-nas" element={<Page4 />} />
          <Route index path="/kontakt" element={<Page5 />} />
          <Route index path="/AI" element={<Page6 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


