import Container from "./Container";
import logo from "../assets/images/Group_logo.png";
import { Link } from "react-router-dom";
import hamburgerIcon from "../assets/images/hamburgerIcon.png";
import {useRef} from "react";
import {HamburgerIcon, Logo} from "./svgComponents/svg";
import AI_menu from "../assets/images/AI_menu.png";


function Header() {
  return (
    <header className="header hidden sm:block">
      <Container preClassName="h-full" className="h-full">
        <div className="sm:flex items-center justify-between hidden h-full">
          <Link to="/home">
            <img src={logo} className="w-[130px] h-[29px]" />
          </Link>
          <div className="flex gap-3 sm:gap-6 lg:gap-9 text-base font-bold items-center">
            <Link to="/AI">
            <img src={AI_menu} className="w-[30px] h-[30px] hover-effect" />
            </Link>
            <Link to="/home/#projekti">
              <span className="hover:text-red">Projects</span>
            </Link>
            <Link to="/home/#storitve">
              <span className="hover:text-red">Services</span>
            </Link>
            <Link to="/kontakt">
              <div className="my-btn px-7 py-1.5 text-[13px] bg-[#E5313A] rounded-[10px] hover:bg-primary hover:transform()">
                Contact
              </div>
            </Link>
          </div>
        </div>
      </Container>
      <div className="border-[#5454544D] border-[0.05px]" />
    </header>
  );
}

export const MobileHeader = () => {
  const menu = useRef<HTMLDivElement>(null);
  function hamburgerClicked() {
    if (menu.current) {
      if (menu.current.style.display === "flex") {
        menu.current.style.display = "none";
      } else {
        menu.current.style.display = "flex";
      }
    }
  }
  return (
    <nav className="header sm:hidden px-3">
      <Container preClassName="h-full">
        <div className="flex items-center justify-between w-full h-full">
        <Link to="/home">
            <img src={logo} className="w-[130px] h-[29px]" />
          </Link>
          <div onClick={hamburgerClicked} className="w-[29px] h-[29px]">
            <HamburgerIcon/>
          </div>
        </div>
        <div className="hidden justify-center items-center w-full h-fit bg-[#0f0f0f] px-10 rounded-b-[10px] z-50 py-5"
             ref={menu}>
          <div className="w-full h-full justify-center items-center">
            <div className="flex flex-col gap-4 text-base font-bold items-center w-full">
              <Link to="/AI" className="border-b-2 border-[#545454] w-full flex justify-center pb-2">
                <img src={AI_menu} className="w-[30px] h-[30px]" />
              </Link>
              <Link to="/home/#projekti" className="border-b-2 border-[#545454] w-full flex justify-center pb-2">
                <span className="hover:text-red">Projects</span>
              </Link>
              <Link to="/oglasevanje" className="border-b-2 border-[#545454] w-full flex justify-center pb-2">
                <span className="hover:text-red">Advertising</span>
              </Link>
              <Link to="/graficna-podoba" className="border-b-2 border-[#545454] w-full flex justify-center pb-2">
                <span className="hover:text-red">Graphic design</span>
              </Link>
              <Link to="/spletni-razvoj" className="border-b-2 border-[#545454] w-full flex justify-center pb-2">
                <span className="hover:text-red">Web development</span>
              </Link>
              <Link to="/kontakt">
                <div
                    className="my-btn px-7 py-1.5 text-[13px] bg-[#E5313A] rounded-[10px] hover:bg-primary hover:transform()">
                  Contact
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Header;
