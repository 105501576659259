import React from "react";
import icon1 from "../assets/images/Cursor in Window.png";
import image1 from "../assets/images/web-development.png";
import icon2 from "../assets/images/openai-logo 5.png";
import image2 from "../assets/images/our-advantage.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import useDocumentTitle from "../helper/useDocumentTitle";

function Page1() {
  useDocumentTitle("GROV | Web development");
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col gap-20 pt-9 sm:pt-14 px-3 sm:px-0">
        <Card
          title="Web development"
          icon={icon1}
          src={image1}
          p1="In the GROV team, it's not just about writing code; together with you, we want to establish a strategic partnership that will help you navigate the constantly evolving digital world. Because we understand the importance of good collaboration, our approach is designed to seamlessly integrate with your team and together create the right environment for developing the software solutions you need."
          p2="In our team, we focus on lean development principles, with an emphasis on waste reduction and value increase, enabling us to concentrate on developing features that truly benefit your business. Our commitment to regular communication with weekly technical updates and frequent meetings allows us to receive feedback in real-time and adjust our work accordingly. We prioritize working hand in hand with your team, emphasizing frequent communication for swift resolution of any concerns and ensuring that the final product meets the requirements and vision of your company."
        />
        <Card
          title="Our advantage"
          icon={icon2}
          src={image2}
          p1="Our website development platform stands out for integrating the latest technologies, such as AI (artificial intelligence). This enables better analytics, personalization, task automation, enhanced security, and faster development. The integration of these technologies helps us achieve goals more quickly and efficiently, resulting in better outcomes for our clients."
          p2="In our development, we utilize OPEN AI technology, specifically the GPT-4 model. It assists us in solving problems more quickly and efficiently, enabling us to achieve desired results faster."
        />
      </div>
    </>
  );
}

export default Page1;
