import React from "react";
import icon1 from "../assets/images/phone-icon.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import useDocumentTitle from "../helper/useDocumentTitle";

function Page5() {
  useDocumentTitle("GROV | Contact");
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });
  const [formSubmitStatus, setFormSubmitStatus] = React.useState({
    submitting: false,
    submitted: false,
    error: false,
  });

  function formSubmit(e: React.FormEvent) {
    const encode = (data: {
      [x: string]: string;
    }) => {
      return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    e.preventDefault();
    setFormSubmitStatus({ submitting: true, submitted: false, error: false });
    // netlify form submission
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData })
    })
        .then(res => {
          /* simulate delay */
          if (res.status === 200) {
            setTimeout(() => {
              setFormSubmitStatus({ submitting: false, submitted: true, error: false });
            }, 1000);
          } else {
            throw new Error("Error in form submission");
          }
          console.log("Form submitted")
        })
        .catch(error => {
          /* simulate delay */
          setTimeout(() => {
            setFormSubmitStatus({ submitting: false, submitted: false, error: true });
          }, 1000);
          console.error(error)
        });
  }

  return (
      <>
        <MobileHeader />
        <div className="flex flex-col sm:gap-20 pt-9 sm:pt-14 px-3 sm:px-0">
          <Card
              title="Work with us"
              icon={icon1}
              p1="This is the right place for anyone looking for an excellent team to develop products. Don't hesitate, and leave us an email, give us a call, or simply fill out the form."
              imgAltElement={
                <div className="h-full w-full relative flex justify-center flex-col gap-4 sm:pl-8 sm:pt-0 pt-5">
              <span className="text-xl font-bold mt-2.5">
                Send us a message!
              </span>
                  {formSubmitStatus.submitting ? (
                      <>
                        <div
                            className={
                              "w-full h-[370px] sm:h-[312px] bg-white rounded-[10px] flex items-center justify-center"
                            }
                        >
                          <div className="animate-spin h-10 aspect-square text-black z-10 border-l-black rounded-full border-4"></div>
                        </div>
                      </>
                  ) : formSubmitStatus.submitted ? (
                      <>
                        <div
                            className={
                              "w-full h-[370px] sm:h-[312px] bg-transparent rounded-[10px] flex items-center justify-center"
                            }
                        >
                          <div className="animate-check-mark h-16 aspect-square z-10 rounded-full bg-white flex justify-center items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                            >
                              <path
                                  className="stroke-black"
                                  d="M 2 14 L 10 22 L 26 6"
                                  fill="none"
                                  strokeWidth="4"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </>
                  ) : formSubmitStatus.error ?
                      (
                          <>
                            <div
                                className={
                                  "w-full h-[370px] sm:h-[312px] bg-transparent rounded-[10px] flex items-center justify-center"
                                }
                            >
                              <div className="animate-check-mark h-16 aspect-square z-10 rounded-full bg-white flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                >
                                  {/* a cross path here indicating error */}
                                  <path
                                      className="stroke-black"
                                      d="M 2 2 L 26 26 M 26 2 L 2 26"
                                      fill="none"
                                      strokeWidth="4"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </>
                      ):
                      (
                          <>
                              <form
                                  name="contact"
                                  className="flex flex-col gap-4 text-base h-[370px] sm:h-[312px]"
                                  onSubmit={(e) => {
                                      formSubmit(e);
                                  }}
                              >
                                  <div className="w-full grid sm:grid-cols-2 gap-4">
                                      <input
                                          className="px-3 py-2 rounded-2xl bg-white focus:outline-0 text-black"
                                          required
                                          type="text"
                                          name="name"
                                          placeholder="Name"
                                          onChange={(e) => {
                                              setFormData({...formData, name: e.target.value});
                                          }}
                                      />
                                      <input
                                          className="px-3 py-2 rounded-2xl focus:outline-0 bg-white text-black"
                                          required
                                          type="email"
                                          name="email"
                                          placeholder="Email"
                                          onChange={(e) => {
                                              setFormData({...formData, email: e.target.value});
                                          }}
                                      />
                                  </div>
                                  <textarea
                                      className="px-3 py-2 rounded-2xl focus:outline-0 min-h-[200px] bg-white text-black"
                                      required
                                      placeholder="Message"
                                      name="message"
                                      onChange={(e) => {
                                          setFormData({...formData, message: e.target.value});
                                      }}
                                  ></textarea>
                                  <div className="w-full flex items-center">
                                      <button
                                          type="submit"
                                          className="py-2 bg-white w-full text-black rounded-2xl font-bold hover:bg-gray-300 transition-all duration-300"
                                      >
                                          Send
                                      </button>
                                  </div>
                              </form>
                          </>
                      )}
                </div>
              }
          >
              <div className="flex flex-col gap-3">
                  <span className="text-xl font-bold">Get in touch with us</span>
              <div className="flex flex-col text-sm text-[#FFF]">
                <span>grovagency@gmail.com</span>
                <span>+386 041 470 627</span>
              </div>
            </div>
          </Card>
        </div>
      </>
  );
}

export default Page5;