import React from "react";

function Container({
  children,
  preClassName,
  className,
  ...props
}: {
  children: React.ReactElement | React.ReactElement[];
  preClassName?: string;
  className?: string;
}) {
  return (
    <div className={`pre-container ${preClassName}`}>
      <div {...props} className={`container sm:mx-0 mx-4 ${className}`}>
        {children}
      </div>
    </div>
  );
}

export default Container;
