export const Logo = () => (
    <svg className="w-full h-full" viewBox="0 0 115 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use href="#image0_14_26" transform="matrix(0.00221205 0 0 0.00877193 -0.0032418 0)"/>
            </pattern>
            <image id="image0_14_26" width="455" height="114"
                   href="src\assets\images\Group_logo.png"/>
        </defs>
    </svg>
)

export const HamburgerIcon = () => (
    <svg
        width="34"
        height="34"
        viewBox="0 0 24 24"
        fill="none"

        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 6H20M4 12H20M4 18H20"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)


