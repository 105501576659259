import { ReactElement } from "react";
import Container from "../Container";

const Card = ({
  title,
  src,
  icon,
  p1,
  p2,
  p3,
  p4,
  children,
  imgAltElement,
  flag,
}: {
  title: string;
  flag?: boolean;
  src?: string;
  icon: string;
  p1: string;
  p2?: string;
  p3?: string;
  p4?: string;
  children?: ReactElement | ReactElement[];
  imgAltElement?: ReactElement;
}) => {
  return (
    <Container>
      <div className="flex flex-col sm:flex-row items-center sm:items-start sm:gap-4 gap-8 w-full h-full">
        <div>
          <div className="h-[61px] w-[61px] flex justify-center items-center rounded-full bg-primary shadow-lg shadow-[#FF2E2E]">
            <img className="h-[33px] w-[33px]" src={icon} />
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full sm:max-w-[561px]">
          <span className="sm:text-4xl text-3xl mt-3 font-bold gap-6 text-center sm:text-start">
            {title}
          </span>
          <div
            className={`flex flex-col sm:flex-row gap-6 lg:gap-16 justify-start items-start sm:pt-6 pt-10"`}
          >
            <div className="flex flex-col gap-6 text-sm">
              <p className="text-justify">{p1}</p>
              <p className="text-justify">{p2}</p>
              {p3 && <p className="text-justify">{p3}</p>}
              {p4 && <p className="text-justify">{p4}</p>}
              {children && children}
            </div>
          </div>
        </div>
        {imgAltElement && imgAltElement}
        {src && (
          <div className="w-full flex justify-around self-center">
            <>
              {!flag && <div className="sm:block hidden" />}
              <img className="w-full h-full max-w-[224px]" src={src} />
            </>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Card;
