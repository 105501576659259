import React from "react";
import icon1 from "../assets/images/Cursor in Window.png";
import image1 from "../assets/images/web-development.png";
import icon2 from "../assets/images/openai-logo 5.png";
import image2 from "../assets/images/our-advantage.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import useDocumentTitle from "../helper/useDocumentTitle";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import bookImage from "../assets/images/book.png";

function Page1() {
  useDocumentTitle("GROV | AI");
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col gap-4 md:gap-6 lg:gap-8 pt-4 md:pt-6 lg:pt-8 px-3 md:px-6 lg:px-8">
        <Card
          title="Stay ahead with AI"
          icon={icon2}
          src={image2}
          p1="Seamless Integration With Your Favorite AI Tool."
          p2="We develop innovative digital solutions utilizing cutting-edge AI tools such as Chat GPT and seamlessly integrate them in your systems. With our agile and rapid development, we are able to quickly deliver you custom AI business solutions that set you apart from your competition, while staying attentive to the market needs. From business process optimization to AI enhanced user experience, we can help you take your business to the next level."
        />
<Container>
  <div className="flex justify-center pt-10">
    <div className="px-3 sm:px-0 w-full lg:w-[905px] h-[231px] flex justify-center items-center relative">
    <img src={bookImage} alt="Book" className="rounded-[10px] object-cover w-full h-full" />
      <div className="flex flex-col w-fit text-3xl font-bold gap-20 items-center z-10 absolute">
        <span className="text-center">Book a free 15 minute call with us</span>
        <a href="https://calendly.com/grovagency/klic" target="_blank" rel="noopener noreferrer">
          <div className="my-btn px-8 py-1 rounded-lg text-base text-black bg-white font-bold z-10">
            Book
          </div>
        </a>
      </div>
    </div>
  </div>
</Container>
      </div>
    </>
  );
}

export default Page1;




