import React from "react";
import icon1 from "../assets/images/Post Ads.png";
import image1 from "../assets/images/advertising.png";
import icon2 from "../assets/images/Handshake Heart.png";
import image2 from "../assets/images/we-advice.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import useDocumentTitle from "../helper/useDocumentTitle";

function Page3() {
  useDocumentTitle("GROV | advertising");
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col gap-20 pt-9 sm:pt-14 px-3 sm:px-0">
        <Card
          title="Advertising"
          icon={icon1}
          src={image1}
          p1="We develop and implement advertising strategies that are in line with today's digital trends. With our innovative approach and knowledge of the latest trends in digital marketing, we focus on advertising through platforms such as TikTok and Meta."
          p2="For us, TikTok and Meta are advertising channels that we extensively leverage due to their extensive reach and influence on the target audience. We understand that creating attractive and relevant content is crucial for success on these platforms. Therefore, our team of creatives and marketing experts closely collaborates on creating advertising campaigns tailored to these platforms and responsive to their specificities."
          p3="For us, it's not only important to follow trends but also to actively shape them. By continuously monitoring and adjusting strategies based on results analysis, we ensure that our campaigns on TikTok and Meta are relevant, attractive, and effective."
        />
        <Card
          title="We advise"
          icon={icon2}
          src={image2}
          p1="In advising and executing projects, we focus on the entire process, which includes planning, execution, and monitoring. Our approach to consulting is comprehensive, meaning that we are your reliable partner throughout the entire development cycle."
          p2="We begin with a thorough understanding of your goals and needs. Then, we formulate a strategy tailored to your company and the specifics of the project. We incorporate our expertise and experience into every step, guiding you through the process from start to finish."
          p3="Our team is committed to providing recommendations, advice, and expert opinions based on best practices and current trends in the field we specialize in. By establishing open communication channels along with constant monitoring of project progress, we ensure that you are always involved and informed at every stage."
        />
      </div>
    </>
  );
}

export default Page3;
