import Container from "../components/Container";
import icon1 from "../assets/images/Cursor in Window.png";
import image1 from "../assets/images/square-logo.png";
import { MobileHeader } from "../components/Header";
import Card from "../components/cards/Card";
import { Link } from "react-router-dom";
import useDocumentTitle from "../helper/useDocumentTitle";

function Page4() {
  useDocumentTitle("GROV | About us");
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col sm:gap-20 pt-9 sm:pt-14 px-3 sm:px-0">
        <Card
          title="About us"
          icon={icon1}
          src={image1}
          flag={true}
          p1="The essence of our agency extends beyond the ordinary framework of digital creation. We are pioneers, the first digital agency refining our services with advanced artificial intelligence. In designing websites, graphic identities, and advertisements, we surpass established boundaries by employing advanced AI technology, bringing innovation and efficiency to every project."
          p2="Our passion lies in creating innovative, refined, and functional digital solutions. With our unique use of artificial intelligence, we are leading the way in creating websites that boast intuitive user experiences, graphic identities that captivate, and advertisements that leave a lasting impression on your target audience."
          p3="Throughout our workflow, we excel in constant adaptability and continuous improvement. Artificial intelligence enables us to precisely understand your needs and goals, creating tailored and highly personalized solutions. Each project is designed with the purpose of surpassing expectations and delivering exceptional results."
          p4="We are committed to creating innovative digital experiences with artificial intelligence that are not only inspiring but also functional and geared towards achieving your business goals. Welcome to the agency where tradition meets the future - where artificial intelligence transforms the way we perceive and shape the digital world."
        />
        <Container>
          <div className="flex justify-center pt-10">
            <div className="px-3 sm:px-0 bottom-banner overlay-bottom-banner rounded-[10px] w-full lg:w-[905px] h-[231px] flex justify-center items-center relative">
              <div className="flex flex-col w-fit text-3xl font-bold gap-4 items-center z-10">
                <span className="text-center">Start new project</span>
                <Link to="/kontakt">
                  <div className="my-btn px-8 py-1 rounded-lg text-base text-black bg-white font-bold z-10">
                    Contact
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Page4;
